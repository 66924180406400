import * as Actions from './actions';
import * as ActionTypes from './actiontypes'
import * as Service from './services'
import { takeEvery, call, put, all} from 'redux-saga/effects'

export function* fetchCustomers() {
    try {
        const data = yield call(Service.getCustomers);

        yield put(Actions.LOAD_CUSTOMERS_SUCCESS_TYPE(data))
        if (data.status === 200) {
        }
    } catch (error) {
        yield put(Actions.LOAD_CUSTOMERS_ERROR(error))
    }
}
export function* fetchRoles() {
    try {
        const data = yield call(Service.getRoles);

        yield put(Actions.LOAD_ROLES_SUCCESS_TYPE(data))
        if (data.status === 200) {
        }
    } catch (error) {
        yield put(Actions.LOAD_ROLES_ERROR(error))
    }
}
export function* fetchApplications() {
    try {
        const data = yield call(Service.getApplications);

        yield put(Actions.LOAD_APPLICATIONS_SUCCESS_TYPE(data))
        if (data.status === 200) {
        }
    } catch (error) {
        yield put(Actions.LOAD_APPLICATIONS_ERROR(error))
    }
}

export function* fetchLocations(action) {
    try {
        const data = yield call(Service.getLocations,action.payload.customerId);
        yield put(Actions.LOAD_LOCATIONS_SUCCESS_TYPE(data))
        if (data.status === 200) {

        }
    } catch (error) {
        yield put(Actions.LOAD_LOCATIONS_ERROR(error))
    }
}
export function* fetchUsers(action) {
    try {
        const data = yield call(Service.getUsers,action.payload.customerId, action.payload.locationId);
        yield put(Actions.LOAD_USERS_SUCCESS_TYPE(data))
        if (data.status === 200) {

        }
    } catch (error) {
        yield put(Actions.LOAD_USERS_ERROR(error))
    }
}
export function* addUser(action) {
    try {
        const data = yield call(Service.addUser,action.payload.user);
        yield put(Actions.ADD_USER_SUCCESS_TYPE(data))
        if (data.status === 200) {

        }
    } catch (error) {
        yield put(Actions.ADD_USER_ERROR(error));
    }
}
export function* editUser(action) {
    try {
        const data = yield call(Service.editUser,action.payload.user,action.payload.userId);
        yield put(Actions.EDIT_USER_SUCCESS_TYPE(data))
        if (data.status === 200) {

        }
    } catch (error) {
        yield put(Actions.EDIT_USER_ERROR(error))
    }
}
export function* deleteUser(action) {
    try {
        const data = yield call(Service.deleteUser,action.payload.user,action.payload.userId);
        yield put(Actions.DELETE_USER_SUCCESS_TYPE(data))
        if (data.status === 200) {

        }
    } catch (error) {
        yield put(Actions.DELETE_USER_ERROR(error))
    }
}


export function* EffectListener() {
    yield takeEvery(ActionTypes.LOAD_CUSTOMERS, fetchCustomers);
    yield takeEvery(ActionTypes.LOAD_ROLES, fetchRoles);
    yield takeEvery(ActionTypes.LOAD_APPLICATIONS, fetchApplications);
    yield takeEvery(ActionTypes.LOAD_LOCATIONS, fetchLocations);
    yield takeEvery(ActionTypes.LOAD_USERS, fetchUsers);
    yield takeEvery(ActionTypes.ADD_USER, addUser);
    yield takeEvery(ActionTypes.EDIT_USER, editUser);
    yield takeEvery(ActionTypes.DELETE_USER, deleteUser);
}
