import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { Box, Button, Toolbar } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import * as Actions from "../actions"
import AddEditUserModal from './AddEditUserModal';
import { CircularProgress, Grid, InputAdornment, TextField, Typography, Stack } from '@mui/material';

// import SearchIcon from "../../../assets/searchIcon.svg";



const UserList = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertNotification, setAlertNotification] = useState('');
  const [userData, setUserData] = useState(props.users);

  const editUser = (params) => {
    return <Button variant="contained" sx={{width:"150px"}} onClick={() => {
      props.setSelectedUser(params.row);
      setShowModal(true);
    }}>Edit User</Button>
  }
  const columns = [
    {
      field: 'last_name',
      headerName: 'Last Name',
      width: 150,
      editable: false,
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      width: 150,
      editable: false,
    },
    {
      field: 'username',
      headerName: 'User Name',
      width: 150,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email ID',
      width: 200,
      editable: false,
    },
    {
      field: 'central_role_id',
      headerName: 'User Type',
      width: 90,
      editable: false,
    },
    {
      field: 'active',
      headerName: 'Status',
      width: 90,
      editable: false,
    },

    {
      field: 'editUser',
      headerName: 'Edit',
      width: 150,
      renderCell: editUser
    }
  ];
  const handleAddUser = () => {
    props.setSelectedUser(null);
    setShowModal(true);
  }
  const handleClose = () => {
    props.setSelectedUser(null);
    setShowModal(false);

  }

  const handleChange = (value) => {
    props.updateSearchInput(value);
  }

  useEffect(() => {
    if (props.selectedCustomer && props.addOrEditSuccess) {
      props.fetchUsers(props.selectedCustomer.central_customer_id, props.selectedLocation.central_location_id);
      setShowModal(false);
    }
  }, [props.addOrEditSuccess]);

  useEffect(() => {
    console.log(props.alertNotification);
    if (props.alertNotification) {
      setAlertNotification(props.alertNotification);
      if ((alertNotification.message === 'User added successfully') || (alertNotification.message === 'User updated successfully')) {
        setLoading(false);
      }
      else if (alertNotification.type === 'error') {
        setLoading(false);
      }
      else{
        return () => {}
      }
    }
  }, [props.alertNotification, alertNotification])

  useEffect(() => {
    if (props.searchInput) {
        runSearchQuery(props.searchInput);
    } else if (props.users) {
        setUserData(props.users);
    }
  }, [props.searchInput, props.users]);

  const runSearchQuery = (searchInput) => {
    if (props.users) {
      const filteredUserData = props.users.filter((row) => {
        return (
            (row.last_name && row.last_name.toString().toUpperCase().includes(searchInput.toUpperCase())) ||
            (row.first_name && row.first_name.toString().toUpperCase().includes(searchInput.toUpperCase())) ||
            (row.username && row.username.toString().toUpperCase().includes(searchInput.toUpperCase())) ||
            (row.email && row.email.toString().toUpperCase().includes(searchInput.toUpperCase())) ||
            (row.central_role_id && row.central_role_id.toString().toUpperCase().includes(searchInput.toUpperCase())) ||
            (row.active && row.active.toString().toUpperCase().includes(searchInput.toUpperCase()))
        );
    });
    setUserData(filteredUserData)
  }
};

  return (
    <Box >
          <Box >
          <h3 style={{ marginBottom: "10px", float: "left" }}>Users:</h3>
      <TextField
         value={props.searchInput}
         onChange={(e) => {
             handleChange(e.target.value);
         }}
         autoComplete="off"
         sx={{
             borderRadius: "4px",
             input: {
                 color: "#033F53",
             },
             width: "20%",
             background: "#fff",
             boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
             "& .MuiInputBase-root.Mui-disabled": {
                 background: "#E0E2EA",
                 color: "#A6A6A6",
             },
             marginLeft: "20px",
             marginTop: "20px"
         }}
         size={"small"}
         placeholder="Search"
         id="outlined-start-adornment"
         InputProps={{
             startAdornment:
                 <InputAdornment position="start">
                     {/* <img src={SearchIcon} alt="" /> */}
                 </InputAdornment>
         }}
      />
      </Box>
      {props.selectedCustomer && <Toolbar sx={{ justifyContent: "flex-end", paddingRight: "4px" }}>
        <Button variant="outlined" onClick={handleAddUser} >Add User</Button>
      </Toolbar>}
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={userData ? userData : []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[20]}
          disableRowSelectionOnClick
          getRowId={(row) => row.central_user_id}
        />
      </Box>
      <AddEditUserModal showModal={showModal} modalTitle={props.selectedUser ? "Edit User" : "Add User"} handleClose={handleClose} loading={loading} setLoading={setLoading} />
    </Box>

  );
}
const mapStateToProps = (state) => {
  return {
    users: state.users,
    selectedUser: state.selectedUser,
    addOrEditSuccess: state.addOrEditSuccess,
    selectedCustomer: state.selectedCustomer,
    selectedLocation: state.selectedLocation,
    alertNotification: state.alertNotification,
    searchInput: state.searchInput,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedUser: (user) => dispatch(Actions.SET_SELECTED_USER(user)),
    fetchUsers: (customerId) => dispatch(Actions.LOAD_USERS(customerId)),
    updateSearchInput: (searchInput) => dispatch(Actions.SEARCH_INPUT_UPDATE(searchInput))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserList);
