import * as ActionTypes from './actiontypes'

export const LOAD_APPLICATIONS = (authToken) => {
    return {
        type: ActionTypes.LOAD_APPLICATIONS,
        payload: { authToken }
    }
}

export const LOAD_APPLICATIONS_SUCCESS_TYPE = (data) => {
    return {
        type: ActionTypes.LOAD_APPLICATIONS_SUCCESS_TYPE,
        payload: data
    }
}
export const LOAD_APPLICATIONS_FAILURE_TYPE = (error) => {
    return {
        type: ActionTypes.LOAD_APPLICATIONS_FAILURE_TYPE,
        error: error
    }
}
export const LOAD_VOR_APPLICATIONS = (authToken) => {
    return {
        type: ActionTypes.LOAD_VOR_APPLICATIONS,
        payload: { authToken }
    }
}

export const LOAD_VOR_APPLICATIONS_SUCCESS_TYPE = (data) => {
    return {
        type: ActionTypes.LOAD_VOR_APPLICATIONS_SUCCESS_TYPE,
        payload: data
    }
}
export const LOAD_VOR_APPLICATIONS_FAILURE_TYPE = (error) => {
    return {
        type: ActionTypes.LOAD_VOR_APPLICATIONS_FAILURE_TYPE,
        error: error
    }
}
export const LOAD_DEPLOYMENT_TYPES = (authToken) => {
    return {
        type: ActionTypes.LOAD_DEPLOYMENT_TYPES,
        payload: { authToken }
    }
}

export const LOAD_DEPLOYMENT_TYPES_WITH_SUCCESS = (data) => {
    return {
        type: ActionTypes.LOAD_DEPLOYMENT_TYPES_WITH_SUCCESS,
        payload: data
    }
}
export const LOAD_DEPLOYMENT_TYPES_WITH_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_DEPLOYMENT_TYPES_WITH_ERROR,
        error: error
    }
}
export const LOAD_CUSTOMERS = (authToken) => {
    return {
        type: ActionTypes.LOAD_CUSTOMERS,
        payload: { authToken }
    }
}
export const LOAD_CUSTOMERS_SUCCESS_TYPE = (data) => {
    return {
        type: ActionTypes.LOAD_CUSTOMERS_SUCCESS_TYPE,
        payload: data
    }
}
export const LOAD_CUSTOMERS_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_CUSTOMERS_ERROR,
        error: error
    }
}
export const CUSTOMER_ADD_EDIT_MODAL_UPDATE = (type, show, selectedData) => {
    return {
        type: ActionTypes.CUSTOMER_ADD_EDIT_MODAL_UPDATE,
        payload: { type, show, selectedData }
    }
}
export const CREATE_CUSTOMER = (customername, customerjdenumber, shortname, status, applications, callUpdatedCustomerList) => {
    return {
        type: ActionTypes.CREATE_CUSTOMER,
        payload: { customername, customerjdenumber, shortname, status, applications, callUpdatedCustomerList }
    }
}
export const CREATE_CUSTOMER_SUCCESS = (data) => {
    return {
        type: ActionTypes.CREATE_CUSTOMER_SUCCESS,
        payload: data
    }
}
export const CREATE_CUSTOMER_ERROR = (error) => {
    return {
        type: ActionTypes.CREATE_CUSTOMER_ERROR,
        error: error
    }
}
export const EDIT_CUSTOMER = (customerid, customername, customerjdenumber, shortname, status, applications, callUpdatedCustomerList) => {
    return {
        type: ActionTypes.EDIT_CUSTOMER,
        payload: { customerid, customername, customerjdenumber, shortname, status, applications, callUpdatedCustomerList }
    }
}
export const EDIT_CUSTOMER_SUCCESS = (data) => {
    return {
        type: ActionTypes.EDIT_CUSTOMER_SUCCESS,
        payload: data
    }
}
export const EDIT_CUSTOMER_ERROR = (error) => {
    return {
        type: ActionTypes.EDIT_CUSTOMER_ERROR,
        error: error
    }
}
export const LOAD_LOCATIONS = (selectedCustomerId) => {
    return {
        type: ActionTypes.LOAD_LOCATIONS,
        payload: { selectedCustomerId }
    }
}

export const LOAD_LOCATIONS_SUCCESS_TYPE = (data) => {
    return {
        type: ActionTypes.LOAD_LOCATIONS_SUCCESS_TYPE,
        payload: data
    }
}
export const LOCATION_ADD_EDIT_MODAL = (type, show, selectedData) => {
    return {
        type: ActionTypes.LOCATION_ADD_EDIT_MODAL,
        payload: { type, show, selectedData }
    }
}
export const CREATE_LOCATION = (customerId, locationName, locationJDENumber, address, latitude, longitude, status, email, timezone, callUpdatedLocationList,wareHouseId) => {
    return {
        type: ActionTypes.CREATE_LOCATION,
        payload: { customerId, locationName, locationJDENumber, address, latitude, longitude, status, email, timezone, callUpdatedLocationList,wareHouseId }
    }
}
export const CREATE_LOCATION_SUCCESS = (data) => {
    return {
        type: ActionTypes.CREATE_LOCATION_SUCCESS,
        payload: data
    }
}
export const CREATE_LOCATION_ERROR = (error) => {
    return {
        type: ActionTypes.CREATE_LOCATION_ERROR,
        error: error
    }
}

export const EDIT_LOCATION = (locationId, customerId, locationName, locationJDENumber, address, latitude, longitude, status, email, timezone, callUpdatedLocationList,wareHouseId) => {
    return {
        type: ActionTypes.EDIT_LOCATION,
        payload: { locationId, customerId, locationName, locationJDENumber, address, latitude, longitude, status, email, timezone, callUpdatedLocationList,wareHouseId }
    }
}
export const EDIT_LOCATION_SUCCESS = (data) => {
    return {
        type: ActionTypes.EDIT_LOCATION_SUCCESS,
        payload: data
    }
}
export const EDIT_LOCATION_ERROR = (error) => {
    return {
        type: ActionTypes.EDIT_LOCATION_ERROR,
        error: error
    }
}

export const LOAD_CUSTOMERS_WITH_DC = () => {
    return {
        type: ActionTypes.LOAD_CUSTOMERS_WITH_DC,
        payload: {}
    }
}
export const LOAD_CUSTOMERS_WITH_DC_SUCCESS = (customers) => {
    return {
        type: ActionTypes.LOAD_CUSTOMERS_WITH_DC_SUCCESS,
        payload: { customers: customers }
    }
}
export const LOAD_EDGEPCS = (distributionId) => {
    return {
        type: ActionTypes.LOAD_EDGEPCS,
        payload: { distributionId }
    }
}
export const LOAD_EDGEPCS_WITH_SUCCESS = (edgePCs) => {
    return {
        type: ActionTypes.LOAD_EDGEPCS_WITH_SUCCESS,
        payload: { edgePCs: edgePCs }
    }
}
export const LOAD_EDGEPCS_WITH_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_EDGEPCS_WITH_ERROR,
        payload: { error }
    }
}
export const LOAD_EDGEPCS_CONFIGURATION = (edgePcId) => {
    return {
        type: ActionTypes.LOAD_EDGEPCS_CONFIGURATION,
        payload: { edgePcId }
    }
}
export const LOAD_EDGEPCS_CONFIGURATION_WITH_SUCCESS = (edgePCsConfiguration) => {
    return {
        type: ActionTypes.LOAD_EDGEPCS_CONFIGURATION_WITH_SUCCESS,
        payload: { edgePCsConfiguration }
    }
}
export const LOAD_EDGEPCS_CONFIGURATION_WITH_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_EDGEPCS_CONFIGURATION_WITH_ERROR,
        payload: { error }
    }
}
export const LOAD_EMBEDDED_URL = () => {
    return {
        type: ActionTypes.LOAD_EMBEDDED_URL,
        payload: { }
    }
}

export const LOAD_EMBEDDED_URL_SUCCESS = (data) => {
    return {
        type: ActionTypes.LOAD_EMBEDDED_URL_SUCCESS,
        payload: data
    }
}
export const LOAD_EMBEDDED_URL_FAILURE = (error) => {
    return {
        type: ActionTypes.LOAD_EMBEDDED_URL_FAILURE,
        payload: { error }
    }
}

export const LOAD_SITE_HEALTH_METRICS = (selectedCustomerId, selectedLocationId, selectedTranDate, halfPallets, fullPallets) => {
    return {
        type: ActionTypes.LOAD_SITE_HEALTH_METRICS,
        payload: { selectedCustomerId, selectedLocationId, selectedTranDate, halfPallets, fullPallets }
    }
}

export const LOAD_SITE_HEALTH_METRICS_WITH_SUCCESS = (data) => {
    return {
        type: ActionTypes.LOAD_EMBEDDED_URL_SUCCESS,
        payload: data
    }
}
export const LOAD_SITE_HEALTH_METRICS_WITH_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_SITE_HEALTH_METRICS_WITH_ERROR,
        payload: { error }
    }
}

export const CONFIGURATION_ADD_EDIT_MODAL = (type, show, selectedData) => {
    return {
        type: ActionTypes.CONFIGURATION_ADD_EDIT_MODAL,
        payload: { type, show, selectedData }
    }
}
export const COPY_CONFIGURATION_ADD_EDIT_MODAL = (type, show, selectedData) => {
    return {
        type: ActionTypes.COPY_CONFIGURATION_ADD_EDIT_MODAL,
        payload: { type, show, selectedData }
    }
}
export const CREATE_VOR_CONFIGURATION = (customerId, dcId, edgePcId, propertyName, propertyValue, statusTemp, callUpdatedConfigurationList) => {
    return {
        type: ActionTypes.CREATE_VOR_CONFIGURATION,
        payload: { customerId, dcId, edgePcId, propertyName, propertyValue, statusTemp, callUpdatedConfigurationList }
    }
}
export const CREATE_VOR_CONFIGURATION_SUCCESS = (data) => {
    return {
        type: ActionTypes.CREATE_VOR_CONFIGURATION_SUCCESS,
        payload: data
    }
}
export const CREATE_VOR_CONFIGURATION_ERROR = (error) => {
    return {
        type: ActionTypes.CREATE_VOR_CONFIGURATION_ERROR,
        error: error
    }
}
export const COPY_CREATE_VOR_CONFIGURATION = (customerId, dcId, edgePcId, properties, callUpdatedConfigurationList) => {
    return {
        type: ActionTypes.COPY_CREATE_VOR_CONFIGURATION,
        payload: { customerId, dcId, edgePcId, properties, callUpdatedConfigurationList }
    }
}
export const COPY_CREATE_VOR_CONFIGURATION_SUCCESS = (data) => {
    return {
        type: ActionTypes.COPY_CREATE_VOR_CONFIGURATION_SUCCESS,
        payload: data
    }
}
export const COPY_CREATE_VOR_CONFIGURATION_ERROR = (error) => {
    return {
        type: ActionTypes.COPY_CREATE_VOR_CONFIGURATION_ERROR,
        error: error
    }
}
export const EDIT_VOR_CONFIGURATION = (configurationId, customerId, dcId, edgePcId, propertyName, propertyValue, status, callUpdatedConfigurationList) => {
    return {
        type: ActionTypes.EDIT_VOR_CONFIGURATION,
        payload: { configurationId, customerId, dcId, edgePcId, propertyName, propertyValue, status, callUpdatedConfigurationList }
    }
}
export const EDIT_VOR_CONFIGURATION_SUCCESS = (data) => {
    return {
        type: ActionTypes.EDIT_VOR_CONFIGURATION_SUCCESS,
        payload: data
    }
}
export const EDIT_VOR_CONFIGURATION_ERROR = (error) => {
    return {
        type: ActionTypes.EDIT_VOR_CONFIGURATION_ERROR,
        error: error
    }
}
export const LOAD_PROPERTY_NAMES = (applicationId, edgePcId) => {
    return {
        type: ActionTypes.LOAD_PROPERTY_NAMES,
        payload: { applicationId, edgePcId }
    }
}
export const LOAD_PROPERTY_NAMES_SUCCESS = (propertyNames) => {
    return {
        type: ActionTypes.LOAD_PROPERTY_NAMES_SUCCESS,
        payload: { propertyNames: propertyNames }
    }
}
export const LOAD_PROPERTY_NAMES_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_PROPERTY_NAMES_ERROR,
        payload: { error }
    }
}
export const LOAD_PROPERTY_NAMES_EDIT = (applicationId) => {
    return {
        type: ActionTypes.LOAD_PROPERTY_NAMES_EDIT,
        payload: { applicationId }
    }
}
export const LOAD_PROPERTY_NAMES_EDIT_SUCCESS = (propertyNamesEdit) => {
    return {
        type: ActionTypes.LOAD_PROPERTY_NAMES_EDIT_SUCCESS,
        payload: { propertyNamesEdit: propertyNamesEdit }
    }
}
export const LOAD_PROPERTY_NAMES_EDIT_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_PROPERTY_NAMES_EDIT_ERROR,
        payload: { error }
    }
}
export const LOAD_BRANDS = (authToken, customerId) => {
    return {
        type: ActionTypes.LOAD_BRANDS,
        payload: { authToken, customerId }
    }
}
export const LOAD_BRANDS_SUCCESS = (data) => {
    return {
        type: ActionTypes.LOAD_BRANDS_SUCCESS,
        payload: { data }
    }
}
export const LOAD_BRANDS_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_BRANDS_ERROR,
        payload: { error }
    }
}
export const LOAD_PACKAGE_TYPE = (authToken, customerId) => {
    return {
        type: ActionTypes.LOAD_PACKAGE_TYPE,
        payload: { authToken, customerId }
    }
}
export const LOAD_PACKAGE_TYPE_SUCCESS = (data) => {
    return {
        type: ActionTypes.LOAD_PACKAGE_TYPE_SUCCESS,
        payload: { data }
    }
}
export const LOAD_PACKAGE_TYPE_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_PACKAGE_TYPE_ERROR,
        payload: { error }
    }
}
export const LOAD_SKUSBY_DESC = (authToken, customerId) => {
    return {
        type: ActionTypes.LOAD_SKUSBY_DESC,
        payload: { authToken, customerId }
    }
}
export const LOAD_SKUSBY_DESC_WITH_SUCCESS = (data) => {
    return {
        type: ActionTypes.LOAD_SKUSBY_DESC_WITH_SUCCESS,
        payload: { data }
    }
}
export const LOAD_SKUSBY_DESC_WITH_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_SKUSBY_DESC_WITH_ERROR,
        payload: { error }
    }
}
export const LOAD_SKUSBY_REFRENCE = (searchOption,referenceNumber, upc,customerId) => {
    return {
        type: ActionTypes.LOAD_SKUSBY_REFRENCE,
        payload: { searchOption,referenceNumber, upc,customerId }
    }
}
export const LOAD_SKUSBY_REFRENCE_WITH_SUCCESS = (data) => {
    return {
        type: ActionTypes.LOAD_SKUSBY_REFRENCE_WITH_SUCCESS,
        payload: { data }
    }
}
export const LOAD_SKUSBY_REFRENCE_WITH_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_SKUSBY_REFRENCE_WITH_ERROR,
        payload: { error }
    }
}

export const UPDATE_WEIGHT = (action, upc, reference, weight, height, length, width, packageTypeId, unitsPerCase, brandId, skuPackagesPerCase, isWeightVerified, dateweightverifiedCopy, selectedDate,customerId,ignoreSKU) => {
    return {
        type: ActionTypes.UPDATE_WEIGHT,
        payload: { action, upc, reference, weight, height, length, width, packageTypeId, unitsPerCase, brandId, skuPackagesPerCase, isWeightVerified, dateweightverifiedCopy, selectedDate,customerId,ignoreSKU }
    }
}
export const UPDATE_WEIGHT_SUCCESS = (data) => {
    return {
        type: ActionTypes.UPDATE_WEIGHT_SUCCESS,
        payload: data
    }
}
export const UPDATE_WEIGHT_ERROR = (error) => {
    return {
        type: ActionTypes.UPDATE_WEIGHT_ERROR,
        error: error
    }
}
export const MERGE_WEIGHT = (action, customerId, delPackageTypeId, mergePackageTypeId) => {
    return {
        type: ActionTypes.MERGE_WEIGHT,
        payload: { action, customerId, delPackageTypeId, mergePackageTypeId }
    }
}
export const MERGE_WEIGHT_SUCCESS = (data) => {
    return {
        type: ActionTypes.MERGE_WEIGHT_SUCCESS,
        payload: data
    }
}
export const MERGE_WEIGHT_ERROR = (error) => {
    return {
        type: ActionTypes.MERGE_WEIGHT_ERROR,
        error: error
    }
}
export const LOAD_VOR_CUSTOMERS = (authToken) => {
    return {
        type: ActionTypes.LOAD_VOR_CUSTOMERS,
        payload: { authToken }
    }
}
export const LOAD_VOR_CUSTOMERS_SUCCESS = (data) => {
    return {
        type: ActionTypes.LOAD_VOR_CUSTOMERS_SUCCESS,
        payload: data
    }
}
export const LOAD_VOR_CUSTOMERS_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_VOR_CUSTOMERS_ERROR,
        error: error
    }
}

export const LOAD_ROLES = () => {
    return {
        type: ActionTypes.LOAD_ROLES,
        payload: {}
    }
}
export const LOAD_ROLES_SUCCESS_TYPE = (data) => {
    return {
        type: ActionTypes.LOAD_ROLES_SUCCESS_TYPE,
        payload: data
    }
}
export const LOAD_ROLES_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_ROLES_ERROR,
        error: error
    }
}

export const LOAD_APPLICATIONS_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_APPLICATIONS_ERROR,
        error: error
    }
}

export const LOAD_LOCATIONS_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_LOCATIONS_ERROR,
        error: error
    }
}
export const LOAD_USERS = (customerId, locationId) => {
    return {
        type: ActionTypes.LOAD_USERS,
        payload: { customerId: customerId, locationId: locationId }
    }
}
export const LOAD_USERS_SUCCESS_TYPE = (data) => {
    return {
        type: ActionTypes.LOAD_USERS_SUCCESS_TYPE,
        payload: data
    }
}
export const LOAD_USERS_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_USERS_ERROR,
        error: error
    }
}
export const SET_SELECTED_CUSTOMER = (customer) => {
    return {
        type: ActionTypes.SET_SELECTED_CUSTOMER,
        payload: { customer: customer }
    }
}
export const SET_SELECTED_LOCATION = (location) => {
    return {
        type: ActionTypes.SET_SELECTED_LOCATION,
        payload: { location: location }
    }
}
export const SET_SELECTED_USER = (user) => {
    return {
        type: ActionTypes.SET_SELECTED_USER,
        payload: { user: user }
    }
}
export const ADD_USER = (user) => {
    return {
        type: ActionTypes.ADD_USER,
        payload: { user: user }
    }
}
export const ADD_USER_SUCCESS_TYPE = (data) => {
    return {
        type: ActionTypes.ADD_USER_SUCCESS_TYPE,
        payload: data
    }
}
export const ADD_USER_ERROR = (error) => {
    return {
        type: ActionTypes.ADD_USER_ERROR,
        payload: error
    }
}
export const EDIT_USER = (user, userId) => {
    return {
        type: ActionTypes.EDIT_USER,
        payload: { user: user, userId: userId }
    }
}
export const EDIT_USER_SUCCESS_TYPE = (data) => {
    return {
        type: ActionTypes.EDIT_USER_SUCCESS_TYPE,
        payload: data
    }
}
export const DELETE_USER_SUCCESS_TYPE = (data) => {
    return {
        type: ActionTypes.EDIT_USER_SUCCESS_TYPE,
        payload: data
    }
}
export const EDIT_USER_ERROR = (error) => {
    return {
        type: ActionTypes.EDIT_USER_ERROR,
        payload: error
    }
}
export const LOAD_DOCK_DOOR = (dcId) => {
    return {
        type: ActionTypes.LOAD_DOCK_DOOR,
        payload: { dcId }
    }
}
export const LOAD_DOCK_DOOR_SUCCESS = (data) => {
    return {
        type: ActionTypes.LOAD_DOCK_DOOR_SUCCESS,
        payload: data
    }
}
export const LOAD_DOCK_DOOR_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_DOCK_DOOR_ERROR,
        error: error
    }
}
export const LOAD_EDGE_SERIALS = (deviceType) => {
    return {
        type: ActionTypes.LOAD_EDGE_SERIALS,
        payload: { deviceType }
    }
}
export const LOAD_EDGE_SERIALS_WITH_SUCCESS = (edgeSerials) => {
    return {
        type: ActionTypes.LOAD_EDGE_SERIALS_WITH_SUCCESS,
        payload: { edgeSerials }
    }
}
export const CREATE_DOCK_DOOR = (dockDoorName, slsPortalIp, topic, rpDeviceIp, selectedEdgeSerials, status, customerId, dcId) => {
    return {
        type: ActionTypes.CREATE_DOCK_DOOR,
        payload: { dockDoorName, slsPortalIp, topic, rpDeviceIp, selectedEdgeSerials, status, customerId, dcId }
    }
}
export const CREATE_DOCK_DOOR_SUCCESS = (data) => {
    return {
        type: ActionTypes.CREATE_DOCK_DOOR_SUCCESS,
        payload: data
    }
}
export const CREATE_DOCK_DOOR_ERROR = (error) => {
    return {
        type: ActionTypes.CREATE_DOCK_DOOR_ERROR,
        error: error
    }
}
export const EDIT_DOCK_DOOR = (dockDoorId, dockDoorName, slsPortalIp, topic, rpDeviceIp, selectedEdgeSerials, status, customerId, dcId) => {
    return {
        type: ActionTypes.EDIT_DOCK_DOOR,
        payload: { dockDoorId, dockDoorName, slsPortalIp, topic, rpDeviceIp, selectedEdgeSerials, status, customerId, dcId }
    }
}
export const EDIT_DOCK_DOOR_SUCCESS = (data) => {
    return {
        type: ActionTypes.EDIT_DOCK_DOOR_SUCCESS,
        payload: data
    }
}
export const EDIT_DOCK_DOOR_ERROR = (error) => {
    return {
        type: ActionTypes.EDIT_DOCK_DOOR_ERROR,
        error: error
    }
}
export const LOAD_NETWORK_CONFIGURATION = (dcId) => {
    return {
        type: ActionTypes.LOAD_NETWORK_CONFIGURATION,
        payload: { dcId }
    }
}
export const LOAD_NETWORK_CONFIGURATION_WITH_SUCCESS = (networkConfiguration) => {
    return {
        type: ActionTypes.LOAD_NETWORK_CONFIGURATION_WITH_SUCCESS,
        payload: { networkConfiguration }
    }
}
export const LOAD_NETWORK_CONFIGURATION_WITH_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_NETWORK_CONFIGURATION_WITH_ERROR,
        payload: { error }
    }
}
export const NETWORK_CONFIGURATION_ADD_EDIT_MODAL = (type, show, selectedData) => {
    return {
        type: ActionTypes.NETWORK_CONFIGURATION_ADD_EDIT_MODAL,
        payload: { type, show, selectedData }
    }
}
export const CREATE_NETWORK_CONFIGURATION = (customerId, dcId, dnsAddress, gatewayAddress, netmaskAddress, callUpdatedNetworkConfigurationList) => {
    return {
        type: ActionTypes.CREATE_NETWORK_CONFIGURATION,
        payload: { customerId, dcId, dnsAddress, gatewayAddress, netmaskAddress, callUpdatedNetworkConfigurationList }
    }
}
export const CREATE_NETWORK_CONFIGURATION_SUCCESS = (data, callUpdatedNetworkConfigurationList) => {
    return {
        type: ActionTypes.CREATE_NETWORK_CONFIGURATION_SUCCESS,
        payload: data, callUpdatedNetworkConfigurationList
    }
}
export const CREATE_NETWORK_CONFIGURATION_ERROR = (error) => {
    return {
        type: ActionTypes.CREATE_NETWORK_CONFIGURATION_ERROR,
        error: error
    }
}
export const EDIT_NETWORK_CONFIGURATION = (customerId, dcId, dnsAddress, networkConfigID, gatewayAddress, netmaskAddress, callUpdatedNetworkConfigurationList) => {
    return {
        type: ActionTypes.EDIT_NETWORK_CONFIGURATION,
        payload: { customerId, dcId, dnsAddress, networkConfigID, gatewayAddress, netmaskAddress, callUpdatedNetworkConfigurationList }
    }
}
export const EDIT_NETWORK_CONFIGURATION_SUCCESS = (data) => {
    return {
        type: ActionTypes.EDIT_NETWORK_CONFIGURATION_SUCCESS,
        payload: data
    }
}
export const EDIT_NETWORK_CONFIGURATION_ERROR = (error) => {
    return {
        type: ActionTypes.EDIT_NETWORK_CONFIGURATION_ERROR,
        error: error
    }
}
export const LOAD_EDGEPCS_TWO = (distributionId) => {
    return {
        type: ActionTypes.LOAD_EDGEPCS_TWO,
        payload: { distributionId }
    }
}
export const LOAD_EDGEPCS_TWO_WITH_SUCCESS = (edgePCs) => {
    return {
        type: ActionTypes.LOAD_EDGEPCS_TWO_WITH_SUCCESS,
        payload: { edgePCs: edgePCs }
    }
}
export const LOAD_EDGEPCS_TWO_WITH_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_EDGEPCS_TWO_WITH_ERROR,
        payload: { error }
    }
}
export const LOAD_EDGEPCS_CONFIGURATION_TWO = (edgePcId) => {
    return {
        type: ActionTypes.LOAD_EDGEPCS_CONFIGURATION_TWO,
        payload: { edgePcId }
    }
}
export const LOAD_EDGEPCS_CONFIGURATION_TWO_WITH_SUCCESS = (edgePCsConfiguration) => {
    return {
        type: ActionTypes.LOAD_EDGEPCS_CONFIGURATION_TWO_WITH_SUCCESS,
        payload: { edgePCsConfiguration }
    }
}
export const LOAD_EDGEPCS_CONFIGURATION_TWO_WITH_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_EDGEPCS_CONFIGURATION_TWO_WITH_ERROR,
        payload: { error }
    }
}
export const LOAD_PALLET_CONFIGURATION = (customerId, dcId) => {
    return {
        type: ActionTypes.LOAD_PALLET_CONFIGURATION,
        payload: { customerId, dcId }
    }
}
export const LOAD_PALLET_CONFIGURATION_WITH_SUCCESS = (data) => {
    return {
        type: ActionTypes.LOAD_PALLET_CONFIGURATION_WITH_SUCCESS,
        payload: { data }
    }
}
export const LOAD_PALLET_CONFIGURATION_WITH_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_PALLET_CONFIGURATION_WITH_ERROR,
        payload: { error }
    }
}
export const CREATE_PALLET_CONFIGURATION = (customerId, dcId, palletType, palletSchema, palletWeight, isfullPallet, callUpdatedPalletConfig) => {
    return {
        type: ActionTypes.CREATE_PALLET_CONFIGURATION,
        payload: { customerId, dcId, palletType, palletSchema, palletWeight, isfullPallet, callUpdatedPalletConfig }
    }
}
export const CREATE_PALLET_CONFIGURATION_SUCCESS = (data, callUpdatedPalletConfig) => {
    return {
        type: ActionTypes.CREATE_PALLET_CONFIGURATION_SUCCESS,
        payload: data, callUpdatedPalletConfig
    }
}
export const CREATE_PALLET_CONFIGURATION_ERROR = (error) => {
    return {
        type: ActionTypes.CREATE_PALLET_CONFIGURATION_ERROR,
        error: error
    }
}
export const EDIT_PALLET_CONFIGURATION = (customerId, dcId, palletType, palletSchema, palletWeight, isfullPallet, palletSchemaID, callUpdatedPalletConfig) => {
    return {
        type: ActionTypes.EDIT_PALLET_CONFIGURATION,
        payload: { customerId, dcId, palletType, palletSchema, palletWeight, isfullPallet, palletSchemaID, callUpdatedPalletConfig }
    }
}
export const EDIT_PALLET_CONFIGURATION_SUCCESS = (data) => {
    return {
        type: ActionTypes.EDIT_PALLET_CONFIGURATION_SUCCESS,
        payload: data
    }
}
export const EDIT_PALLET_CONFIGURATION_ERROR = (error) => {
    return {
        type: ActionTypes.EDIT_PALLET_CONFIGURATION_ERROR,
        error: error
    }
}

export const LOAD_CODE_DEPLOYMENT = (dcId) => {
    return {
        type: ActionTypes.LOAD_CODE_DEPLOYMENT,
        payload: { dcId }
    }
}

export const LOAD_CODE_DEPLOYMENT_WITH_SUCCESS = (data) => {
    return {
        type: ActionTypes.LOAD_CODE_DEPLOYMENT_WITH_SUCCESS,
        payload: data
    }
}

export const LOAD_CODE_DEPLOYMENT_WITH_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_CODE_DEPLOYMENT_WITH_ERROR,
        error: error
    }
}

export const CREATE_CODE_DEPLOYMENT = (customerId, dcId, dayOfTheWeek, startTime, endTime, callUpdatedCodeDeployList) => {
    return {
        type: ActionTypes.CREATE_CODE_DEPLOYMENT,
        payload: { customerId, dcId, dayOfTheWeek, startTime, endTime, callUpdatedCodeDeployList }
    }
}

export const CREATE_CODE_DEPLOYMENT_WITH_SUCCESS = (data) => {
    return {
        type: ActionTypes.CREATE_CODE_DEPLOYMENT_WITH_SUCCESS,
        payload: data
    }

}

export const CREATE_CODE_DEPLOYMENT_WITH_ERROR = (error) => {
    return {
        type: ActionTypes.CREATE_CODE_DEPLOYMENT_WITH_ERROR,
        error: error
    }
}

export const EDIT_CODE_DEPLOYMENT = (customerId, dcId, dayOfTheWeek, startTime, endTime, codeDeployWindowId, callUpdatedCodeDeployList) => {
    return {
        type: ActionTypes.EDIT_CODE_DEPLOYMENT,
        payload: { customerId, dcId, dayOfTheWeek, startTime, endTime, codeDeployWindowId, callUpdatedCodeDeployList }
    }
}

export const EDIT_CODE_DEPLOYMENT_WITH_SUCCESS = (data) => {
    return {
        type: ActionTypes.EDIT_CODE_DEPLOYMENT_WITH_SUCCESS,
        payload: data
    }
}

export const EDIT_CODE_DEPLOYMENT_WITH_ERROR = (error) => {
    return {
        type: ActionTypes.EDIT_CODE_DEPLOYMENT_WITH_ERROR,
        error: error
    }
}
export const DELETE_CODE_DEPLOYMENT = (codeDeployWindowId, callUpdatedCodeDeployList) => {
    return {
        type: ActionTypes.DELETE_CODE_DEPLOYMENT,
        payload: { codeDeployWindowId, callUpdatedCodeDeployList }
    }
}
export const DELETE_CODE_DEPLOYMENT_WITH_SUCCESS = (data) => {
    return {
        type: ActionTypes.DELETE_CODE_DEPLOYMENT_WITH_SUCCESS,
        payload: data
    }
}
export const DELETE_CODE_DEPLOYMENT_WITH_ERROR = (error) => {
    return {
        type: ActionTypes.DELETE_CODE_DEPLOYMENT_WITH_ERROR,
        error: error
    }
}
export const CREATE_EDGE_DEPLOYMENT = (selectedCustomerId, selectedLocationId, selectedEdgePcId, deploymentType, modelSetid, scheduleDate, applicationId, isFullCustomerDeploy, isFullDcDeploy, isEdgePcDeploy) => {
    return {
        type: ActionTypes.CREATE_EDGE_DEPLOYMENT,
        payload: { selectedCustomerId, selectedLocationId, selectedEdgePcId, deploymentType, modelSetid, scheduleDate, applicationId, isFullCustomerDeploy, isFullDcDeploy, isEdgePcDeploy }
    }
}
export const CREATE_EDGE_DEPLOYMENT_WITH_SUCCESS = (data) => {
    return {
        type: ActionTypes.CREATE_EDGE_DEPLOYMENT_WITH_SUCCESS,
        payload: data
    }
}

export const RESET_CREATE_EDGE_DEPLOY_FLAGS = () => {
    return {
        type: ActionTypes.RESET_CREATE_EDGE_DEPLOY_FLAGS,
    }
}
export const CREATE_EDGE_DEPLOYMENT_WITH_ERROR = (error) => {
    return {
        type: ActionTypes.CREATE_EDGE_DEPLOYMENT_WITH_ERROR,
        error: error
    }
}

export const LOAD_DEPLOYMENT_DETAILS = () => {
    return {
        type: ActionTypes.LOAD_DEPLOYMENT_DETAILS,
        payload: {}
    }
}

export const LOAD_DEPLOYMENT_DETAILS_SUCCESS = (data) => {
    return {
        type: ActionTypes.LOAD_DEPLOYMENT_DETAILS_SUCCESS,
        payload: data
    }
}

export const LOAD_DEPLOYMENT_DETAILS_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_DEPLOYMENT_DETAILS_ERROR,
        payload: error
    }
}

export const GET_MODEL_SKU_DATA = (deploymentSheduleID, modelSetID, deploymentDate) => {
    return {
        type: ActionTypes.GET_MODEL_SKU_DATA,
        payload: {deploymentSheduleID, modelSetID, deploymentDate}
    }
}

export const GET_MODEL_SKU_DATA_SUCCESS = (data) => {
    return {
        type: ActionTypes.GET_MODEL_SKU_DATA_SUCCESS,
        payload: data
    }
}

export const GET_MODEL_SKU_DATA_ERROR = (error) => {
    return {
        type: ActionTypes.GET_MODEL_SKU_DATA_ERROR,
        payload: error
    }
}


export const RESET_ERROR = () => {
    return {
        type: ActionTypes.RESET_ERROR,
    }
}
