import * as ActionTypes from './actiontypes'

export const SHOW_NOTIFICATION = (notification) => {
    return {
        type: ActionTypes.SHOW_NOTIFICATION,
        payload: {notification:notification}
    }
}
export const LOAD_CUSTOMERS = () => {
    return {
    type: ActionTypes.LOAD_CUSTOMERS,
    payload: {}
    }
}
export const LOAD_CUSTOMERS_SUCCESS_TYPE = (data) => {
    return {
    type: ActionTypes.LOAD_CUSTOMERS_SUCCESS_TYPE,
    payload: data
    }
}
export const LOAD_CUSTOMERS_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_CUSTOMERS_ERROR,
        error: error
     }
}
export const LOAD_ROLES = () => {
    return {
    type: ActionTypes.LOAD_ROLES,
    payload: {}
    }
}
export const LOAD_ROLES_SUCCESS_TYPE = (data) => {
    return {
    type: ActionTypes.LOAD_ROLES_SUCCESS_TYPE,
    payload: data
    }
}
export const LOAD_ROLES_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_ROLES_ERROR,
        error: error
     }
}
export const LOAD_APPLICATIONS = () => {
    return {
    type: ActionTypes.LOAD_APPLICATIONS,
    payload: {}
    }
}
export const LOAD_APPLICATIONS_SUCCESS_TYPE = (data) => {
    return {
    type: ActionTypes.LOAD_APPLICATIONS_SUCCESS_TYPE,
    payload: data
    }
}
export const LOAD_APPLICATIONS_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_APPLICATIONS_ERROR,
        error: error
     }
}
export const LOAD_LOCATIONS = (customerId) => {
    return {
    type: ActionTypes.LOAD_LOCATIONS,
    payload: {customerId:customerId}
    }
}
export const LOAD_LOCATIONS_SUCCESS_TYPE = (data) => {
    return {
    type: ActionTypes.LOAD_LOCATIONS_SUCCESS_TYPE,
    payload: data
    }
}
export const LOAD_LOCATIONS_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_LOCATIONS_ERROR,
        error: error
     }
}
export const LOAD_USERS = (customerId,locationId) => {
    return {
    type: ActionTypes.LOAD_USERS,
    payload: {customerId:customerId,locationId:locationId}
    }
}
export const LOAD_USERS_SUCCESS_TYPE = (data) => {
    return {
    type: ActionTypes.LOAD_USERS_SUCCESS_TYPE,
    payload: data
    }
}
export const LOAD_USERS_ERROR = (error) => {
    return {
        type: ActionTypes.LOAD_USERS_ERROR,
        error: error
     }
}
export const SET_SELECTED_CUSTOMER = (customer) => {
    return {
        type: ActionTypes.SET_SELECTED_CUSTOMER,
        payload: {customer:customer}
     }
}
export const SET_SELECTED_LOCATION = (location) => {
    return {
        type: ActionTypes.SET_SELECTED_LOCATION,
        payload: {location:location}
     }
}
export const SET_SELECTED_USER = (user) => {
    return {
        type: ActionTypes.SET_SELECTED_USER,
        payload: {user:user}
     }
}
export const ADD_USER = (user) => {
    return {
    type: ActionTypes.ADD_USER,
    payload: {user:user}
    }
}
export const ADD_USER_SUCCESS_TYPE = (data) => {
    return {
    type: ActionTypes.ADD_USER_SUCCESS_TYPE,
    payload: data
    }
}
export const ADD_USER_ERROR = (error) => {
    return {
        type: ActionTypes.ADD_USER_ERROR,
        payload: error
     }
}
export const EDIT_USER = (user,userId) => {
    return {
    type: ActionTypes.EDIT_USER,
    payload: {user:user,userId:userId}
    }
}
export const DELETE_USER = (user,userId) => {
    return {
        type: ActionTypes.DELETE_USER,
        payload: {user:user,userId:userId}
    }
}
export const EDIT_USER_SUCCESS_TYPE = (data) => {
    return {
        type: ActionTypes.EDIT_USER_SUCCESS_TYPE,
        payload: data
    }
}
export const DELETE_USER_SUCCESS_TYPE = (data) => {
    return {
        type: ActionTypes.DELETE_USER_SUCCESS_TYPE,
        payload: data
    }
}
export const EDIT_USER_ERROR = (error) => {
    return {
        type: ActionTypes.EDIT_USER_ERROR,
        payload: error
     }
}
export const DELETE_USER_ERROR = (error) => {
    return {
        type: ActionTypes.DELETE_USER_ERROR,
        payload: error
    }
}

export const SEARCH_INPUT_UPDATE = (searchInput) => {
    return {
        type: ActionTypes.SEARCH_INPUT_UPDATE,
        payload: searchInput
     }
}
