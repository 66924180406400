import * as types from "./actiontypes"

const initialState = {
    customers:[],
    locations:[],
    users:[],
    selectedCustomer:null,
    selectedLocation:null,
    selectedUser:null,
    roles:[],
    applications:[],
    addOrEditSuccess:true,
    alertNotification:{
        show:false,
        type:"success",
        message:""
    },
    searchInput: "",
}
const userManagementReducer = function(state = initialState, action){
    const newState = { ...state };
    switch (action.type){
        case types.SHOW_NOTIFICATION:
            newState.alertNotification = action.payload.notification;
            break;
        case types.LOAD_CUSTOMERS_SUCCESS_TYPE:
            newState.customers = action.payload.data.Data;
            break;
        case types.LOAD_ROLES_SUCCESS_TYPE:
            newState.roles = action.payload.data.Data;
            break;
        case types.LOAD_APPLICATIONS_SUCCESS_TYPE:
            newState.applications = action.payload.data.Data;
            break;
        case types.LOAD_CUSTOMERS_ERROR:
            break;
        case types.LOAD_LOCATIONS_SUCCESS_TYPE:
            newState.locations = action.payload.data.Data;
            break;
        case types.LOAD_LOCATIONS_ERROR:
            newState.locations = [];
            newState.selectedLocation = null;
            break;
        case types.SET_SELECTED_CUSTOMER:
            newState.selectedCustomer = action.payload.customer;
            break;
        case types.SET_SELECTED_LOCATION:
            newState.selectedLocation = action.payload.location;
            break;
        case types.LOAD_USERS_SUCCESS_TYPE:
            newState.users = action.payload.data.Data;
            break;
        case types.SET_SELECTED_USER:
            newState.selectedUser = action.payload.user;
            newState.addOrEditSuccess = false;
            break;
        case types.LOAD_USERS_ERROR:
            newState.users = [];
            break;
        case types.ADD_USER_SUCCESS_TYPE:
            newState.addOrEditSuccess = true;
            newState.alertNotification = {
                show:true,
                type:"success",
                message:"User added successfully"
            }
            break;
        case types.EDIT_USER_SUCCESS_TYPE:
            newState.addOrEditSuccess = true;
            newState.alertNotification = {
                show:true,
                type:"success",
                message:"User updated successfully"
            }
            break;
        case types.DELETE_USER_SUCCESS_TYPE:
            newState.addOrEditSuccess = true;
            newState.alertNotification = {
                show:true,
                type:"success",
                message:"User deleted successfully"
            }
            break;
        case types.ADD_USER_ERROR:
        case types.EDIT_USER_ERROR:
            newState.addOrEditSuccess = false;
            newState.alertNotification = {
                show:true,
                type:"error",
                message:'error in creating/updating user'
            }
            break;
        case types.DELETE_USER_ERROR:
            newState.addOrEditSuccess = false;
            newState.alertNotification = {
                show:true,
                type:"error",
                message:'error in deleting user'
            }
            break;
        case types.SEARCH_INPUT_UPDATE:
            newState.searchInput = action.payload;
            break;
        default:
            break;
    }
    return newState;
}
export default userManagementReducer;
