import React, { useState, useEffect, useDebugValue } from 'react';
import { connect } from 'react-redux'
import { Box, Autocomplete, TextField, Grid, Paper, Button, Stack, Toolbar, Chip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import * as EdgePCActions from '../../../actions/edgepcs';
import { Auth } from 'aws-amplify';
import AddEditEdgePCModal from './AddEditEdgePCModal'
import ConfigureTandemModal from '../Tandem/AddEditTandemModal'
import { useAsyncError } from 'react-router';



const EdgePCList = ({ user, ...props }) => {
  const [customersList, setCustomersList] = useState(props.customers);
  const [distributionCenters, setDistributionCenters] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedDC, setSelectedDC] = useState({});
  const [edgePCs, setEdgePCs] = useState(props.edgePCs);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showConfigureTandem, setShowConfigureTandem] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAddEdgePC = () => {
    props.setSelectedEdgePC(null);
    setShowAddModal(true);
  }
  const tandemDetails = (params) => {
    if (!params.row.tandem_details) return <></>
    else {
      let wrappers = [];
      for (let i = 0; i < params.row.tandem_details.number_of_wrappers; i++) {
        wrappers.push(params.row.tandem_details["wrapper_" + (i + 1)]);
      }
      return (<div>
        {
          wrappers.map((wrapper) => <Chip key={"wrapper_" + wrapper.edge_pc_id} sx={{ marginRight: '5px', marginBottom: "10px" }} label={wrapper.edge_pc_name} />)
        }</div>)
    }

  }
  const editEdgePC = (params) => {
    return <Button variant="contained" onClick={() => {
      props.setSelectedEdgePC(params.row);
      setShowEditModal(true);

    }}>Edit</Button>
  }

  useEffect(() => {
    if(props.edgePCs){
      props.setIndicatorOn(false);
      props.errorResponse = null;
    }
  }, [props.edgePCs])

  const handleModalClose = (refresh) => {
    setShowAddModal(false)
    setShowEditModal(false);
    props.setSelectedEdgePC(null);

  }
  const columns = [
    { field: 'edge_pc_id', headerName: 'ID', width: 70 },
    {
      field: 'pc_name',
      headerName: 'Name',
      width: 150,
      editable: false,
    },
    {
      field: 'wrapper_type_short_name',
      headerName: 'Wrapper Type',
      width: 150,
      editable: false,
    },
    {
      field: 'application_name',
      headerName: 'Application',
      width: 160,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 70,
      editable: false,
    },
    {
      field: 'ip_address',
      headerName: 'IP Address',
      width: 120,
      editable: false,
    },
    {
      field: 'is_tandem',
      headerName: 'Is Tandem',
      width: 80,
      editable: false,
    },
    {
      field: 'ipad_id',
      headerName: 'iPad App id',
      width: 140,
      editable: false,
    },
    {
      field: 'edge_device_generated_serial_number',
      headerName: 'Serial Number',
      width: 140,
      editable: false,
    },
    {
      field: 'is_barcode_scanner_used',
      headerName: 'Barcode Scanner',
      width: 150,
      editable: false,
    }, {
      field: 'tandem_details_id',
      headerName: 'Tandem Details',
      renderCell: tandemDetails,
      minWidth: 300
    },
    user.canWrite && {
      field: 'editEdgePC',
      headerName: 'Edit',
      width: 100,
      renderCell: editEdgePC
    }

  ];

  useEffect(() => {
    if (props.selectedDC && props.addOrEditSuccess)
      props.fetchEdgePC(props.selectedDC.dc_id);
  }, [props.addOrEditSuccess]);

  useEffect(() => {
    setEdgePCs(props.edgePCs);
  }, [props.edgePCs]);

  return (
    <Box >
      <div className="dashboardData-panel">
        <div className="duolift-device-detail-card" style={{ width: "100%" }}>
          {user.canWrite && <Toolbar sx={{ justifyContent: "flex-end" }}>
            <Stack spacing={2} direction="row">
              <Button variant="outlined" onClick={handleAddEdgePC} >Add EdgePC</Button>
            </Stack>
          </Toolbar>
          }
          <div className="detail-card-header">
            <h3 style={{ paddingTop: '10px' }}>Edge PC</h3>
          </div>
          <div className="duolift-device-detail-card-body">
            <div style={{ height: '650px', width: '100%' }}>
              <DataGrid
                rows={edgePCs ? edgePCs : []}
                columns={columns}
                disableVirtualization
                noRowsOverlay={(
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    No rows in DataGrid
                  </Stack>
                )}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                getRowId={(row) => row.edge_pc_id}
              />
              {showAddModal && <AddEditEdgePCModal handleClose={handleModalClose} modalTitle="Add Edge PC" loading={loading}  setLoading={setLoading}/>}
              {showEditModal && <AddEditEdgePCModal handleClose={handleModalClose} modalTitle="Edit Edge PC" loading={loading} setLoading={setLoading}/>}
              {showConfigureTandem && <ConfigureTandemModal handleClose={() => setShowConfigureTandem(false)} modalTitle="Configure Tandem" />}

            </div>
          </div>
        </div>
      </div>

    </Box>

  );
}
const mapStateToProps = (state) => {
  return {
    customers: state.edgePCs.customers,
    selectedData: state.edgePCs.selectedData,
    edgePCs: state.edgePCs.edgePCs,
    addOrEditSuccess: state.edgePCs.addOrEditSuccess,
    selectedDC: state.edgePCs.selectedDistributionCenter,
    selectedCustomer: state.edgePCs.selectedCustomer,
    user: state.application.user,
    errorResponse: state.edgePCs.error,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchCustomersWithDC: () => dispatch(EdgePCActions.LOAD_CUSTOMERS_WITH_DC()),
    fetchEdgePC: (dc_id) => dispatch(EdgePCActions.LOAD_EDGEPCS(dc_id)),
    setSelectedEdgePC: (edgePC) => dispatch(EdgePCActions.SELECT_EDGEPC(edgePC)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EdgePCList);
